import FunnelIcon from '@heroicons/react/24/outline/FunnelIcon';
import RecordIconAllow from '@heroicons/react/24/outline/VideoCameraIcon';
import RecordIconNotAllow from '@heroicons/react/24/outline/VideoCameraSlashIcon';
import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon';
import moment from 'moment';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { supabase } from '../../../data/supabase';
import TitleCard from '../../../shared/components/Cards/TitleCard';
import SearchBar from '../../../shared/components/Input/SearchBar';
import { useAuth } from '../../../shared/context/useAuth';
import useAppoitments from '../../../shared/hooks/useAppointment/patient';
import { openModal } from '../../../store/reducers/modal';
import { MODAL_BODY_TYPES } from '../../../utils/globalConstantUtil';

function TopSideButtons({ removeFilter, applyFilter, applySearch }) {
  const [filterParam, setFilterParam] = useState('');
  const [searchText, setSearchText] = useState('');
  const locationFilters = [
    'pending',
    'em andamento',
    'canceled pac',
    'canceled pro',
    'confirmed',
  ];

  const showFiltersAndApply = (params) => {
    applyFilter(params);
    setFilterParam(params);
  };

  const removeAppliedFilter = () => {
    removeFilter();
    setFilterParam('');
    setSearchText('');
  };

  useEffect(() => {
    if (searchText === '') {
      removeAppliedFilter();
    } else {
      applySearch(searchText);
    }
  }, [searchText]);

  return (
    <div className="inline-block float-right">
      <SearchBar
        searchText={searchText}
        styleClass="mr-4"
        setSearchText={setSearchText}
      />
      {filterParam !== '' && (
        <button
          onClick={() => removeAppliedFilter()}
          className="btn btn-xs mr-2 btn-active btn-ghost normal-case"
        >
          {filterParam}
          <XMarkIcon className="w-4 ml-2" />
        </button>
      )}
      <div className="dropdown dropdown-bottom dropdown-end">
        <label tabIndex={0} className="btn btn-sm btn-outline">
          <FunnelIcon className="w-5 mr-2" />
          Filtro
        </label>
        <ul
          tabIndex={0}
          className="dropdown-content menu p-2 text-sm shadow bg-base-100 rounded-box w-52"
        >
          {locationFilters.map((l, k) => (
            <li key={k}>
              <a onClick={() => showFiltersAndApply(l)}>{l}</a>
            </li>
          ))}
          <div className="divider mt-0 mb-0" />
          <li>
            <a onClick={() => removeAppliedFilter()}>Remover Filtro</a>
          </li>
        </ul>
      </div>
    </div>
  );
}

function AppointmentsPatient() {
  const { patient, currentUser,session } = useAuth();
  const dispatch = useDispatch();
  
  const { data: appointmentsPatient } = useAppoitments(patient && patient[0]?.id );
  const [trans, setTrans] = useState(appointmentsPatient);

  const removeFilter = () => {
    setTrans(appointmentsPatient);
  };

  const applyFilter = (params) => {
    const filteredTransactions = trans?.filter((t) => {
      return t.status === params;
    });
    setTrans(filteredTransactions);
  };

  useEffect(() => {
    const sortData = appointmentsPatient?.sort((a, b) => {
      return b?.start_history?.localeCompare(a?.start_history);
    });

    setTrans(sortData);
  }, [JSON.stringify(appointmentsPatient), JSON.stringify(trans)]);

  // Search according to name
  const applySearch = (value) => {
    const filteredTransactions = trans.filter(
      (t) =>
        t.specialist.name.toLowerCase().includes(value.toLowerCase()) ||
        t.specialist.name.toLowerCase().includes(value.toLowerCase()),
    );
    // var items = ["réservé", "premier", "cliché", "communiqué", "café", "adieu"];

    setTrans(filteredTransactions);
  };
  // TODO - Funcao muito complexa -revisitar
  const handleCancelMeet = async (link, typeVoucher, start) => {
    const Lastday = moment(new Date().toJSON()).subtract(24, 'h');
    if (moment(Lastday).isBefore(moment(start))) {
      await supabase
        .from('appointment')
        .update({ status: 'canceled pac', start: null, end: null })
        .eq('meetLink', link)
        .select();

      const { data } = await supabase
        .from('balance')
        .select('voucher')
        .eq('patient_id', patient[0]?.id)
        .eq('type', typeVoucher)
        .order('id', { ascending: false })
        .limit(1);

      await supabase
        .from('balance')
        .update({
          voucher: data[0].voucher + 1
        })
        .select('voucher')
        .eq('patient_id', patient[0]?.id)
        .eq('type', typeVoucher)
        .order('id', { ascending: false })
        .limit(1);
      toast.success(
        <span>
          Agendamento Cancelado! <br /> Retorno de crédito realizado!
        </span>,
        { closeButton: true },
      );

      window.location.href = '/consulta-cancelada';
    } else {
      await supabase
        .from('appointment')
        .update({ status: 'canceled pac' })
        .eq('meetLink', link)
        .select();
      toast.success(
        <span>
          Agendamento Cancelado! <br /> Sem direito a reembolso!{' '}
        </span>,
        { closeButton: true },
      );
      window.location.href = '/consulta-cancelada';
    }
  };

  const [reviews, setReviews] = useState({});

  function handleReview(data) {
    debugger;
    dispatch(
      openModal({
        title: 'Faça sua avaliação',
        bodyType: MODAL_BODY_TYPES.REVIEW,
        extraObject: {
          specialist: data.specialist,
          patient: currentUser?.id,
          setReviews
        },
      }),
    );
  }


// Função para carregar reviews
const loadReviews = async () => {
  const { data, error } = await supabase
    .from('reviews')
    .select('id_spec, id_pac')
    .eq('id_pac', currentUser?.id);

  if (error) {
    console.error(error);
    toast.error('Erro ao carregar avaliações');
    return;
  }

  // Formate os dados para facilitar o acesso
  const reviewsMap = {};
  data.forEach((review) => {
    reviewsMap[review.id_spec] = true;
  });
  setReviews(reviewsMap);
};

// Carregue as avaliações no `useEffect`
useEffect(() => {
  if (patient?.length > 0) {
    loadReviews();
  }
}, [patient]);

  

  const getDummyStatus = (index, item) => {
    if (index === 'pending')
      return <div className="badge badge-warning">Pendente</div>;
    if (index === 'running')
      return <div className="badge badge-primary">Em andamento</div>;
    if (index === 'canceled pac')
      return (
        <div className="badge badge-error text-white">Cancelado | Paciente</div>
      );
    if (index === 'canceled pro')
      return (
        <div className="badge badge-error text-white">
          Cancelado | Especialista
        </div>
      );
    if (index === 'confirmed')
      return <div className="badge badge-accent">Confirmado</div>;
  
    if (index === 'realizado') {
      const hasReviewed = reviews[item?.specialist?.id]; // Verifica se já existe review
      if (hasReviewed) {
        return (
          <div className="badge badge-success text-white">
            Avaliação feita
          </div>
        );
      }
      return (
        <a
          onClick={() => handleReview(item)}
          className="badge badge-accent cursor-pointer"
        >
          Faça avaliação
        </a>
      );
    }
  
    return <div className="badge badge-ghost">Open</div>;
  };
  

  const location = useLocation();
  function verifyStatus(item) {
    
    if(moment(new Date().toJSON()).isAfter(
      moment(item?.end)
    ) && (item?.status !== 'canceled pac' || item?.status !== 'canceled pro')) {
      return "realizado"
    }
    return item.status
  }


  

  return (
    <>
      {location.pathname !== '/' && (
        <div className="w-full col-span-12">
          <div className="relative p-6 rounded-2xl bg-[#3bbdcf] text-primary-content row-start-1 row-end-2 col-span-3 md:col-span-3 lg:col-span-4">
            <h2 className="font-bold text-2xl mb-6">Minhas Consultas</h2>
            <h2 className="font-light text-xl">Veja suas consultas</h2>
            <p>Acesse links e acompanhe em tempo real o status das consultas</p>
            <img
              src="/common/pro2.png"
              alt="doctor"
              className="absolute bottom-0 right-7 mobile:hidden"
              width={190}
            />
          </div>
        </div>
      )}

      <TitleCard
        topMargin="mt-2"
        TopSideButtons={
          <TopSideButtons
            applySearch={applySearch}
            applyFilter={applyFilter}
            removeFilter={removeFilter}
          />
        }
      >
        {/* Team Member list in table format loaded constant */}
        <div className="overflow-x-auto w-full">
          <table className="table w-full overflow-hidden h-full">
            {trans?.length > 0 && (
              <thead>
                <tr>
                  <th>Especialista</th>
                  <th>Link agenda</th>
                  <th>Tipo de Sessão</th>
                  <th>Data / Hora</th>
                  <th className="w-[10px]">Status</th>
                  <th className="w-[10px]"> </th>
                </tr>
              </thead>
            )}
            <tbody>
              
              {trans?.length > 0 ? (
                trans?.map((l, k) => (
                  <tr key={k}>
                    <td>
                      <div className="flex items-center space-x-3">
                        <div>
                          <div className="font-bold">{l.specialist?.name}</div>
                        </div>
                      </div>
                    </td>
                    <td>
                      {!moment(new Date().toJSON()).isAfter(
                        moment(l.start_history).add(50, 'minutes'),
                      ) && l?.status !== 'canceled pac' ? (
                        <a target="_blank" rel="noreferrer" href={l?.meetLink}>
                          <RecordIconAllow height={22} width={22} />
                        </a>
                      ) : (
                        <div
                          className="tooltip hover:tooltip-open tooltip-bottom"
                          data-tip="Link expirado ou cancelado"
                        >
                          <RecordIconNotAllow height={22} width={22} />
                        </div>
                      )}
                    </td>
                    <td>{l?.category || 'Sem categoria'}</td>
                    <td>
                      {l?.start_history ? (
                        moment(l.start_history).format('D MMM HH:mm')
                      ) : (
                        <p className="text-error">Sem data</p>
                      )}
                    </td>
                    <td>{getDummyStatus(verifyStatus(l), l)}</td>
                    <td className="w-[20px]">
                      {l?.status !== 'canceled pro' &&
                        l?.status !== 'canceled pac' &&
                        !moment(new Date().toJSON()).isAfter(
                          moment(l.start_history),
                        ) && (
                          <div className="dropdown dropdown-left">
                            <div tabIndex={0} role="button" className="">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-6 h-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
                                />
                              </svg>
                            </div>
                            <ul
                              tabIndex={0}
                              className="dropdown-content z-[1] menu  shadow bg-base-100 rounded-box w-32"
                            >
                              <li>
                                <a
                                  onClick={() =>
                                    handleCancelMeet(l?.meetLink, l?.category)
                                  }
                                  className="font-bold"
                                >
                                  Cancelar
                                </a>
                              </li>
                            </ul>
                          </div>
                        )}
                    </td>
                  </tr>
                ))
              ) : (
                <span className="flex pt-6 text-[#666] font-bold">
                  Nada encontrado.
                </span>
              )}
            </tbody>
          </table>
        </div>
      </TitleCard>
    </>
  );
}

export default AppointmentsPatient;
