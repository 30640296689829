import { useQuery } from '@tanstack/react-query';
import { supabase } from '../../../../data/supabase';

const getSpecialists = async () => {
   return supabase
    .from('specialist')
    .select();
};

function useSpecialists() {
  const queryKey = ['specialist'];

  const queryFn = async () => {
    return getSpecialists().then((result) => {
      return result.data
    });
  };

  return useQuery({ queryKey, queryFn });
}

export default useSpecialists;
