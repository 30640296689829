import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { mixed, object, string } from 'yup';
import { supabase } from '../../../../../data/supabase';
import TitleCardDefault from '../../../../../shared/components/Cards/TitleCardDefault';
import { useAuth } from '../../../../../shared/context/useAuth';
import { setAvatar } from '../../../../../store/reducers/avatar';
import { openModal } from '../../../../../store/reducers/modal';
import { MODAL_BODY_TYPES } from '../../../../../utils/globalConstantUtil';
import useProfile from '../../../hooks/UseProfile';
import './index.css';

const genderOptions = [
  { name: 'Selecione', value: '' },
  { name: 'Masculino', value: 'M' },
  { name: 'Feminino', value: 'F' },
  { name: 'Outros', value: 'N' },
];

const schemaForm = object({
  name: string().required('O campo é obrigatório.'),
  email: string().email('E-mail inválido.').required('O campo é obrigatório.'),
  date_of_birth: string().required('O campo é obrigatório.'),
  gender: mixed()
    .required('O campo é obrigatório.')
    .oneOf(['M', 'F', 'N'], 'Selecione uma opção válida.'),
  mobile_number: string().required('O campo é obrigatório.'),
  telephone_number: string().optional(),
  country: string().required('O campo é obrigatório.'),
  state: string().required('O campo é obrigatório.'),
  city: string().required('O campo é obrigatório.'),
  neighborhood: string().optional(),
  street_name: string().required('O campo é obrigatório.'),
  typeSession: mixed()
    .required('O campo é obrigatório.'),
  house_number: string().optional(),
  zip_code: string().required('O campo é obrigatório.'),
  about: string().required('O campo é obrigatório.'),
  certified: string().optional(),
});

function Profile() {
  const {
    specialties,
    media,
    updateProfile,
    uploadFile,
  } = useProfile();

  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onChange", resolver: yupResolver(schemaForm), defaultValues: {
    // ... outros campos
    typeSession: []
  } });
  const fileInputRef = useRef();
  const dispatch = useDispatch();
  const [selectedSkills, setSelectedSkills] = useState([]);
  const { specialist } = useAuth();

  const [selectedTypeSession, setSelectedTypeSession] = useState([]);

  useEffect(() => {
    // Preencher as habilidades selecionadas
    if (specialist[0]?.skills) {
      const skillsOptions = specialist[0].skills.map(skill =>
        typeof skill === 'string' ? { value: skill, label: skill } : skill
      );
      setSelectedSkills(skillsOptions);
    }

    // Preencher o tipo de sessão
    if (specialist[0]?.typeSession) {
      const typeSessionOptions = Array.isArray(specialist[0].typeSession)
        ? specialist[0].typeSession.map(type =>
          typeof type === 'string' ? { value: type, label: type } : type
        )
        : [specialist[0].typeSession]; // Se for um único objeto, coloque-o em um array
      setSelectedTypeSession(typeSessionOptions);
    }
  }, [specialist]);


  const handleFormSubmit = (data) => {
    data.skills = selectedSkills;
    updateProfile(data);
  };

  const handleUploadCertified = (e) => {
    uploadFile(e);
  };

  const handleUploadAvatar = async (event) => {
    try {
      const file = event.target.files[0];
      if (!file) return; // Verifica se um arquivo foi selecionado

      const { data, error } = await supabase.storage
        .from('uploads')
        .upload(`${specialist[0]?.id_user}/avatar/${new Date().getTime()}_${file.name}`, file, {
          upsert: true,
        });

      if (error) {
        throw new Error('Erro ao fazer upload da imagem');
      }

      const avatarUrl = `${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/uploads/${specialist[0]?.id_user}/avatar/${data?.path?.split('/')[2]}`;

      // Atualiza o avatar no Redux e no estado local
      dispatch(setAvatar(avatarUrl));
      toast.success('Foto de perfil atualizada com sucesso');
    } catch (err) {
      console.error('Erro no upload:', err);
      toast.error('Falha ao atualizar a foto de perfil');
    }
  };

  const avatarUrl = useSelector((state) => state.avatar.avatar);

  const handleProfileDisable = (event) => {
    if (!event.target.checked) {
      event.target.checked = true;
      dispatch(
        openModal({
          title: 'DESATIVAR PERFIL',
          bodyType: MODAL_BODY_TYPES.VACATION,
          extraObject: {
            message: 'Qual motivo da desativação do Perfil?',
          },
        }),
      );
    }
  };

  return (
    <div className="card w-full p-6 col-span-12 gap-6 bg-base-100 shadow-xl">
      <TitleCardDefault
        title="Perfil do Especialista"
        subtitle="Dados Pessoais"
        description="Gerencie seus dados e configurações do perfil"
        image="/specialist/doctor.png"
      />
      <div className="indicator col-span-12">
        <div className="card border">
          <div className="card-body">
            <div className="avatar">
              <div className="w-24 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2">
                <img
                  onClick={() =>
                    fileInputRef?.current?.click()
                  }
                  src={avatarUrl || 'https://cdn-icons-png.flaticon.com/512/6596/6596121.png'}
                  alt="avatar"
                />
                <input
                  type="file"
                  name="avatar"
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                  onChange={(e) => handleUploadAvatar(e)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-span-12">
        <form>
          <div className="grid grid-cols-1 desktop:grid-cols-2 gap-6">
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">Nome</span>
              </label>
              <input
                type="text"
                name="name"
                placeholder="Nome"
                className={`input input-bordered w-full  ${errors?.name?.type === 'required' && 'input-error'
                  }`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('name', {
                  value: specialist[0]?.name,
                })}
              />
              <p className="text-error">{errors.name?.message}</p>
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">Email</span>
              </label>
              <input
                type="text"
                name="email"
                placeholder="Email"
                className={`input input-bordered w-full  ${errors?.email?.type === 'required' && 'input-error'
                  }`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('email', {
                  value: specialist[0]?.email,
                })}
              />
              <p className="text-error">{errors.email?.message}</p>
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">
                  Data de Nascimento
                </span>
              </label>
              <input
                type="text"
                name="date_of_birth"
                placeholder="DD/MM/AAAA"
                className={`input input-bordered w-full  ${errors?.date_of_birth?.type === 'required' && 'input-error'
                  }`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('date_of_birth', {
                  value: specialist[0]?.date_of_birth,
                })}
              />
              <p className="text-error">{errors.date_of_birth?.message}</p>
            </div>
            <div className="inline-block">
              <label className="label">
                <div className="label-text">Gênero</div>
              </label>

              <select
                name="gender"
                className="select select-bordered w-full"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('gender', {
                  value: specialist[0]?.gender,
                })}
              >
                {genderOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.name}
                  </option>
                ))}
              </select>
              <p className="text-error">{errors.gender?.message}</p>
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">Celular</span>
              </label>
              <input
                type="text"
                name="mobile_number"
                placeholder="(12) 9999-9999"
                className={`input input-bordered w-full  ${errors?.mobile_number?.type === 'required' && 'input-error'
                  }`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('mobile_number', {
                  value: specialist[0]?.mobile_number,
                })}
              />
              <p className="text-error">{errors.mobile_number?.message}</p>
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">Telefone</span>
              </label>
              <input
                type="text"
                name="telephone_number"
                placeholder="(12) 3333-3333"
                className={`input input-bordered w-full  ${errors?.telephone_number?.type === 'required' && 'input-error'
                  }`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('telephone_number', {
                  value: specialist[0]?.telephone_number,
                })}
              />
            </div>
            <div className="inline-block">
              <label className="label">
                <div className="label-text">País</div>
              </label>

              <input
                type="text"
                name="country"
                placeholder="País"
                defaultValue={specialist[0]?.country}
                className={`input input-bordered w-full  ${errors?.country?.type === 'required' && 'input-error'
                  }`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('country', {
                  value: specialist[0]?.country,
                })}
              />
              <p className="text-error">{errors.country?.message}</p>
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">Estado</span>
              </label>
              <input
                type="text"
                name="state"
                placeholder="SP"
                defaultValue={specialist[0]?.state}
                className={`input input-bordered w-full  ${errors?.state?.type === 'required' && 'input-error'
                  }`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('state', {
                  value: specialist[0]?.state,
                })}
              />
              <p className="text-error">{errors.state?.message}</p>
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">Cidade</span>
              </label>
              <input
                type="text"
                name="city"
                placeholder="São Paulo"
                className={`input input-bordered w-full  ${errors?.city?.type === 'required' && 'input-error'
                  }`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('city', { value: specialist[0]?.city })}
              />
              <p className="text-error">{errors.city?.message}</p>
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">Bairro</span>
              </label>
              <input
                type="text"
                name="neighborhood"
                placeholder="Consolação"
                className={`input input-bordered w-full  ${errors?.neighborhood?.type === 'required' && 'input-error'
                  }`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('neighborhood', {
                  value: specialist[0]?.neighborhood,
                })}
              />
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">Endereço</span>
              </label>
              <input
                type="text"
                name="street_name"
                placeholder="Avenida Paulista"
                className={`input input-bordered w-full  ${errors?.street_name?.type === 'required' && 'input-error'
                  }`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('street_name', {
                  value: specialist[0]?.street_name,
                })}
              />
              <p className="text-error">{errors.street_name?.message}</p>
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">Número</span>
              </label>
              <input
                type="text"
                name="house_number"
                placeholder="1500"
                className={`input input-bordered w-full  ${errors?.house_number?.type === 'required' && 'input-error'
                  }`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('house_number', {
                  value: specialist[0]?.house_number,
                })}
              />
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">CEP</span>
              </label>
              <input
                type="text"
                name="zip_code"
                placeholder="11600-000"
                defaultValue={specialist[0]?.zip_code}
                className={`input input-bordered w-full  ${errors?.zip_code?.type === 'required' && 'input-error'
                  }`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('zip_code', {
                  value: specialist[0]?.zip_code,
                })}
              />
              <p className="text-error">{errors.zip_code?.message}</p>
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">CPF</span>
              </label>
              <input
                type="text"
                id="cpf_number"
                name="cpf_number"
                placeholder="xxx.xxx.xxx-xx"
                className={`input input-bordered w-full  ${errors?.rg_number?.type === 'required' && 'input-error'
                  }`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('cpf_number', {
                  value: specialist[0]?.cpf_number,
                })}
              />
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">RG</span>
              </label>
              <input
                type="text"
                name="rg_number"
                placeholder="xx.xxx.xxx-x"
                defaultValue={specialist[0]?.rg_number}
                className={`input input-bordered w-full  ${errors?.rg_number?.type === 'required' && 'input-error'
                  }`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('rg_number', {
                  value: specialist[0]?.rg_number,
                })}
              />
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">CRP</span>
              </label>
              <input
                type="text"
                name="crp_number"
                placeholder="xx.xxx.xxx-x"
                defaultValue={specialist[0]?.crp_number}
                className={`input input-bordered w-full  ${errors?.crp_number?.type === 'required' && 'input-error'
                  }`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('crp_number', {
                  value: specialist[0]?.crp_number,
                })}
              />
            </div>
            <div className="inline-block">
              <label className="label">
                <div className="label-text">Especialidades</div>
              </label>

              <Select
                name="skills"
                value={selectedSkills}
                options={specialties}
                isMulti
                classNamePrefix="select"
                className="select-bordered"
                onChange={setSelectedSkills}
              />
              <p className="text-error">{errors.skills?.message}</p>
            </div>
            <div className="inline-block">
              <label className="label">
                <div className="label-text">Categoria</div>
              </label>

              <Controller
      name="typeSession"
      control={control}
      rules={{ required: 'O campo é obrigatório.' }}
      render={({ field }) => (
        <Select
          {...field}
          options={[
            { value: 'Individual', label: 'Individual' },
            { value: 'Casal', label: 'Casal' },
            { value: 'Familia', label: 'Família' }
          ]}
          isMulti
          classNamePrefix="select"
          className="select-bordered"
          value={selectedTypeSession}
          onChange={(selectedOptions) => {
            setSelectedTypeSession(selectedOptions);
            field.onChange(selectedOptions);
          }}
        />
      )}
    />
    {errors.typeSession && <p className="text-error">{errors.typeSession.message}</p>}

            </div>
            <div className="form-control w-full">
              <label className="label">
                <div className="label-text">Diploma</div>
              </label>
              <input
                type="file"
                name="certified"
                className={`file-input ${errors.certified?.message ? 'file-input-error' : ''
                  } file-input-bordered w-full`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('certified', {
                  onChange: (e) => handleUploadCertified(e),
                })}
              />
              <p className="text-error">{errors.certified?.message}</p>
              {media.map((item) => (
                <div>
                  <button
                    type="button"
                    className="btn btn-sm btn-ghost"
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/uploads/${specialist[0]?.id}/certified/${item.name}`,
                        '_blank',
                        'noreferrer',
                      )
                    }
                  >
                    Download - {item?.name}
                  </button>
                </div>
              ))}
            </div>
          </div>
          <div className="form-control w-full">
            <label className="label">
              <div className="label-text">Sobre você</div>
            </label>
            <textarea
              name="about"
              className="textarea textarea-bordered w-full"
              defaultValue={specialist[0]?.about}
              placeholder="Bio"
              {...register('about')}
            />
          </div>

          <div className="mt-16">
            <button
              type="button"
              onClick={() => handleSubmit(handleFormSubmit)()}
              className="btn btn-primary float-right"
            >
              Salvar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Profile;
