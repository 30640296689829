
import FunnelIcon from '@heroicons/react/24/outline/FunnelIcon';
import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon';
import { useEffect, useState } from 'react';
import { supabase } from '../../../../data/supabase';
import TitleCard from '../../../../shared/components/Cards/TitleCard';
import SearchBar from '../../../../shared/components/Input/SearchBar';
import { useAuth } from '../../../../shared/context/useAuth';

function TopSideButtons({ removeFilter, applyFilter, applySearch }) {
  const [filterParam, setFilterParam] = useState('');
  const [searchText, setSearchText] = useState('');
  const locationFilters = ['pending', 'em andamento', 'cancelado', 'confirmed'];

  const showFiltersAndApply = (params) => {
    applyFilter(params);
    setFilterParam(params);
  };

  const removeAppliedFilter = () => {
    removeFilter();
    setFilterParam('');
    setSearchText('');
  };

  useEffect(() => {
    if (searchText === '') {
      removeAppliedFilter();
    } else {
      applySearch(searchText);
    }
  }, [searchText]);

  return (
    <div className="inline-block float-right">
      <SearchBar
        searchText={searchText}
        styleClass="mr-4"
        setSearchText={setSearchText}
      />
      {filterParam !== '' && (
        <button
          onClick={() => removeAppliedFilter()}
          className="btn btn-xs mr-2 btn-active btn-ghost normal-case"
        >
          {filterParam}
          <XMarkIcon className="w-4 ml-2" />
        </button>
      )}
      <div className="dropdown dropdown-bottom dropdown-end">
        <label tabIndex={0} className="btn btn-sm btn-outline">
          <FunnelIcon className="w-5 mr-2" />
          Filtro
        </label>
        <ul
          tabIndex={0}
          className="dropdown-content menu p-2 text-sm shadow bg-base-100 rounded-box w-52"
        >
          {locationFilters.map((l, k) => (
            <li key={k}>
              <a onClick={() => showFiltersAndApply(l)}>{l}</a>
            </li>
          ))}
          <div className="divider mt-0 mb-0" />
          <li>
            <a onClick={() => removeAppliedFilter()}>Remover Filtro</a>
          </li>
        </ul>
      </div>
    </div>
  );
}

function History() {
    const { specialistList } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
  
  const [trans, setTrans] = useState([]);
  useEffect(() => {
    setTrans(specialistList);
  }, [specialistList]);

  const removeFilter = () => {
    setTrans(specialistList);
  };

  const applyFilter = (params) => {
    const filteredTransactions = specialistList?.filter((t) => t.status === params);
    setTrans(filteredTransactions);
  };

  // Search according to name
  const applySearch = (value) => {
    const filteredTransactions = specialistList.filter(
      (t) =>
        t.name.toLowerCase().includes(value.toLowerCase()) ||
        t.name.toLowerCase().includes(value.toLowerCase()),
    );
    setTrans(filteredTransactions);
  };

  async function handleApprove(idspecialist) {
    setIsLoading(true);
    try {
      const { data, error } = await supabase
        .from('specialist')
        .update({ allow: true })
        .eq('id_user', idspecialist)
        .select();

      if (error) {
        throw error;
      }

      // Atualizar o estado local com o especialista atualizado
      setTrans((prevState) =>
        prevState.map((spec) =>
          spec.id_user === idspecialist ? { ...spec, allow: true } : spec
        )
      );

      // Atualizar também o specialistList no contexto de autenticação
      // Assumindo que você tem uma função para atualizar o specialistList no contexto
      // updateSpecialistList(updatedSpecialistList);
    } catch (error) {
      console.error("Erro ao aprovar especialista:", error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleStatus = (status, idspecialist) => {
    if (status) {
      return <div className="badge badge-accent">Aprovado</div>;
    }
    return (
      <button 
        onClick={() => handleApprove(idspecialist)} 
        disabled={isLoading}
        className={`badge badge-warning ${isLoading ? 'loading' : ''}`}
      >
        {isLoading ? 'Aprovando...' : 'Aprovar'}
      </button>
    );
  };

  return (
    <>
      {window.location.pathname !== '/' && (
        <div className="w-full col-span-12">
          <div className="relative p-6 rounded-2xl bg-[#3bbdcf] text-primary-content row-start-1 row-end-2 col-span-3 tablet:col-span-3 desktop:col-span-4">
            <h2 className="font-bold text-2xl mb-6">
              Vejas todos os especialista cadastrados
            </h2>
            <h2 className="font-light text-xl">Aprove o acesso do especialista na plataforma.</h2>
            {/* <p>Acesse links e acompanhe em tempo real o status das consultas</p> */}
            <img
              src="/common/pro2.png"
              alt="doctor"
              className="absolute bottom-0 right-7 mobile:hidden"
              width={190}
            />
          </div>
        </div>
      )}

<TitleCard
        topMargin="mt-2"
        TopSideButtons={
          <TopSideButtons
            applySearch={applySearch}
            applyFilter={applyFilter}
            removeFilter={removeFilter}
          />
        }
      >
        <div className="overflow-x-auto w-full">
          <table className="table w-full overflow-hidden h-full">
            {trans?.length > 0 && (
              <thead>
                <tr>
                  <th>Especialista</th>
                  <th>Status</th>
                </tr>
              </thead>
            )}
            <tbody>
              {trans?.length > 0 ? (
                trans.map((dataSpec, k) => (
                  <tr key={k}>
                    <td className="font-bold flex ">
                      <span>
                        {dataSpec?.name || 'Especialista não identificado'}
                      </span>
                    </td>
                    <td>{handleStatus(dataSpec?.allow, dataSpec?.id_user)}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="2">
                    <span className="flex pt-6 text-[#666] font-bold">
                      Nada encontrado.
                    </span>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </TitleCard>
    </>
  );
}

export default History;
