import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { bool, object, string } from 'yup';
import { supabase } from '../../../../../data/supabase';
import TitleCardDefault from '../../../../../shared/components/Cards/TitleCardDefault';
import { useAuth } from '../../../../../shared/context/useAuth';
import { setAvatar } from '../../../../../store/reducers/avatar';
import usePatientProfile from '../../../hooks/UsePatientProfile';

const schemaForm = object({
  name: string().required('O campo é obrigatório.'),
  email: string().email('E-mail inválido.').required('O campo é obrigatório.'),
  mobile_number: string().required('O campo é obrigatório.'),
  telephone_number: string().optional(),
  country: string().required('O campo é obrigatório.'),
  state: string().required('O campo é obrigatório.'),
  city: string().required('O campo é obrigatório.'),
  neighborhood: string().optional(),
  street_name: string().required('O campo é obrigatório.'),
  zip_code: string().required('O campo é obrigatório.'),
  allow: bool().optional(),
});

function PatientProfile() {
  const { currentUser, patient } = useAuth();
  const { updateProfile } = usePatientProfile();
  const [patientData, setPatientData] = useState(null);
  const fileInputRef = useRef();
  const dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schemaForm),
  });

  useEffect(() => {
    const fetchPatientData = async () => {
      const { data, error } = await supabase
        .from('patient')
        .select('*')
        .eq('id_user', currentUser.id)
        .single();

      if (error) {
        console.error('Error fetching patient data:', error);
        return;
      }

      setPatientData(data);
      reset(data);
    };

    if (currentUser) {
      fetchPatientData();
    }
  }, [currentUser, reset]);

  const handleFormSubmit = async (data) => {
    try {
      await updateProfile(data);
      setPatientData(data);
      toast.success('Dados atualizados com sucesso!');
    } catch (error) {
      console.error('Erro ao atualizar os dados:', error);
      toast.error('Falha ao atualizar os dados');
    }
  };

  const handleAvatarClick = () => {
    fileInputRef.current?.click();
  };

  const handleUploadAvatar = async (event) => {
    try {
      const file = event.target.files[0];
      if (!file) return;

      const { data, error } = await supabase.storage
        .from('uploads')
        .upload(`${currentUser.id}/avatar/${Date.now()}_${file.name}`, file, {
          upsert: true,
        });

      if (error) throw error;
      const avatarUrl = `${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/uploads/${currentUser.id}/avatar/${data?.path?.split('/')[2]}`;
      dispatch(setAvatar(avatarUrl));
      setPatientData(prev => ({ ...prev, avatar: data?.path?.split('/')[2] }));
      toast.success('Foto de perfil atualizada com sucesso');
    } catch (err) {
      toast.error('Falha ao atualizar a foto de perfil');
    }
  };


  const avatarUrl = patient[0]?.avatar
    ? `${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/uploads/${currentUser?.id}/avatar/${patient[0]?.avatar}`
    : 'https://cdn-icons-png.flaticon.com/512/6596/6596121.png';


  if (!patientData) {
    return <div>Carregando...</div>;
  }

  return (
    <div className="card w-full p-6 col-span-12 gap-6 bg-base-100 shadow-xl">
      <TitleCardDefault title="Perfil do Paciente" subtitle="Dados Pessoais" description="Gerencie seus dados e configurações do perfil" />
      <div className="indicator col-span-12">
        <div className="card border">
          <div className="card-body">
            <div className="avatar">
              <div className="w-24 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2">
                <img
                  onClick={handleAvatarClick}
                  src={avatarUrl}
                  alt="avatar"
                />
                <input
                  type="file"
                  name="avatar"
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                  onChange={handleUploadAvatar}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="grid grid-cols-1 desktop:grid-cols-2 gap-6">
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">
                  Nome Completo
                </span>
              </label>
              <input
                type="text"
                name="name"
                className={`input input-bordered w-full  ${errors?.name?.type === 'required' && 'input-error'}`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('name')}
              />
              <p className="text-error">{errors.name?.message}</p>
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">Email</span>
              </label>
              <input
                type="text"
                name="email"
                className={`input input-bordered w-full  ${errors?.email?.type === 'required' && 'input-error'}`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('email')}

              />
              <p className="text-error">{errors.email?.message}</p>
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">Celular</span>
              </label>
              <input
                type="text"
                name="mobile_number"
                className={`input input-bordered w-full  ${errors?.mobile_number?.type === 'required' && 'input-error'}`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('mobile_number')}

              />
              <p className="text-error">{errors.mobile_number?.message}</p>
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">Telefone</span>
              </label>
              <input
                type="text"
                name="telephone_number"
                className={`input input-bordered w-full  ${errors?.telephone_number?.type === 'required' && 'input-error'}`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('telephone_number')}

              />
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">Endereço</span>
              </label>
              <input
                type="text"
                name="street_name"
                className={`input input-bordered w-full  ${errors?.street_name?.type === 'required' && 'input-error'}`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('street_name')}
              />
              <p className="text-error">{errors.street_name?.message}</p>
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">
                  Complemento
                </span>
              </label>
              <input
                type="text"
                name="neighborhood"
                className={`input input-bordered w-full  ${errors?.neighborhood?.type === 'required' && 'input-error'}`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('neighborhood')}
              />
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">Cidade</span>
              </label>
              <input
                type="text"
                name="city"
                className={`input input-bordered w-full  ${errors?.city?.type === 'required' && 'input-error'}`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('city')}
              />
              <p className="text-error">{errors.city?.message}</p>
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">Estado</span>
              </label>
              <input
                type="text"
                name="state"
                className={`input input-bordered w-full  ${errors?.state?.type === 'required' && 'input-error'}`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('state')}
              />
              <p className="text-error">{errors.state?.message}</p>
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">
                  Código Postal
                </span>
              </label>
              <input
                type="text"
                name="zip_code"

                className={`input input-bordered w-full  ${errors?.zip_code?.type === 'required' && 'input-error'}`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('zip_code')}

              />
              <p className="text-error">{errors.zip_code?.message}</p>
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">País</span>
              </label>
              <input
                type="text"
                name="country"
                className={`input input-bordered w-full  ${errors?.country?.type === 'required' && 'input-error'}`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('country')}

              />
              <p className="text-error">{errors.country?.message}</p>
            </div>
          </div>
          <div className="divider" />
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="flex flex-col">
              <div className="form-control w-52">
                <label className="cursor-pointer label">
                  <span className="label-text text-base-content">
                    Habilitar Perfil
                  </span>
                  <input
                    type="checkbox"
                    name="allow"
                    className="toggle"
                    {...register('allow')}
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="mt-16">
            <button type="submit" className="btn btn-primary float-right">
              Salvar
            </button>
          </div>
        </form>
    </div>
  );
}

export default PatientProfile;

