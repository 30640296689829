

import { Route } from 'react-router-dom';
import { default as AppointmentsPatientADMIN, default as AppointmentsSpecADMIN } from '../../../shared/transactions/Patient/index-copy';
import { Dashboard, Graphics, History, Settings, Patients, Specialists} from '../pages';
import ListaPatients from '../../../shared//transactions/Patient/patients';
import ListSpecialists from '../../../shared//transactions/Specialist/specialists';


function RoutesAdmin() {
  return (
    <>
      <Route path="/" component={Dashboard} isPrivate />
      <Route path="/consultas" component={Graphics} isPrivate />
      <Route path="/lista-pacientes" component={ListaPatients} isPrivate />
      <Route path="/lista-especialistas" component={ListSpecialists} isPrivate />
      <Route path="/detalhe-paciente" component={Patients} isPrivate />
      <Route path="/detalhe-especialista" component={Specialists} isPrivate />
      <Route path="/agendamentos-pacientes" component={AppointmentsPatientADMIN} isPrivate />
      <Route path="/agendamentos-especialistas" component={AppointmentsSpecADMIN} isPrivate />
      <Route path="/historico" component={History} isPrivate />
      <Route path="/perfil" component={Settings} isPrivate />
    </>
  );
}

export default RoutesAdmin;
