import { useQuery } from '@tanstack/react-query';
import { supabase } from '../../../../data/supabase';

const getPatients = async () => {
   return supabase
    .from('patient')
    .select();
};

function usePatients() {
  const queryKey = ['patient'];

  const queryFn = async () => {
    return getPatients().then((result) => {
      return result.data
    });
  };

  return useQuery({ queryKey, queryFn });
}

export default usePatients;
