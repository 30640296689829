
import FunnelIcon from '@heroicons/react/24/outline/FunnelIcon';
import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon';
import { useEffect, useState } from 'react';
import TitleCard from '../../components/Cards/TitleCard';
import SearchBar from '../../components/Input/SearchBar';
import useSpecialists from './hooks/useSpecialists';
import { Link } from 'react-router-dom';

function TopSideButtons({ removeFilter, applyFilter, applySearch }) {
  const [filterParam, setFilterParam] = useState('');
  const [searchText, setSearchText] = useState('');
  const locationFilters = ['pending', 'em andamento', 'cancelado', 'confirmed'];

  const showFiltersAndApply = (params) => {
    applyFilter(params);
    setFilterParam(params);
  };

  const removeAppliedFilter = () => {
    removeFilter();
    setFilterParam('');
    setSearchText('');
  };

  useEffect(() => {
    if (searchText === '') {
      removeAppliedFilter();
    } else {
      applySearch(searchText);
    }
  }, [searchText]);

  return (
    <div className="inline-block float-right">
      <SearchBar
        searchText={searchText}
        styleClass="mr-4"
        setSearchText={setSearchText}
      />
      {filterParam !== '' && (
        <button
          onClick={() => removeAppliedFilter()}
          className="btn btn-xs mr-2 btn-active btn-ghost normal-case"
        >
          {filterParam}
          <XMarkIcon className="w-4 ml-2" />
        </button>
      )}
      <div className="dropdown dropdown-bottom dropdown-end">
        <label tabIndex={0} className="btn btn-sm btn-outline">
          <FunnelIcon className="w-5 mr-2" />
          Filtro
        </label>
        <ul
          tabIndex={0}
          className="dropdown-content menu p-2 text-sm shadow bg-base-100 rounded-box w-52"
        >
          {locationFilters.map((l, k) => (
            <li key={k}>
              <a onClick={() => showFiltersAndApply(l)}>{l}</a>
            </li>
          ))}
          <div className="divider mt-0 mb-0" />
          <li>
            <a onClick={() => removeAppliedFilter()}>Remover Filtro</a>
          </li>
        </ul>
      </div>
    </div>
  );
}

function ListSpecialists() {
  const { data: specialists } = useSpecialists();
  const [trans, setTrans] = useState(specialists);
  useEffect(() => {
    setTrans(specialists);
  }, [specialists]);

  const removeFilter = () => {
    setTrans(specialists);
  };

  const applyFilter = (params) => {
    const filteredTransactions = trans?.filter((t) => t.status === params);
    setTrans(filteredTransactions);
  };

  // Search according to name
  const applySearch = (value) => {
    const filteredTransactions = trans.filter(
      (t) =>
        t.specialist.name.toLowerCase().includes(value.toLowerCase()) ||
        t.specialist.name.toLowerCase().includes(value.toLowerCase()),
    );
    setTrans(filteredTransactions);
  };

  const pathName = window.location.pathname.split('/')[1];

  return (
    <>
      {pathName !== 'inicio' && (
        <div className="w-full col-span-12">
          <div className="relative p-6 rounded-2xl bg-[#3bbdcf] text-primary-content row-start-1 row-end-2 col-span-3 tablet:col-span-3 desktop:col-span-4">
            <h2 className="font-bold text-2xl mb-6">
              Lista de Especialistas
            </h2>
            <h2 className="font-light text-xl">Análise completa</h2>
            {/* <p>Acesse links e acompanhe em tempo real o status das consultas</p> */}
            <img
              src="/common/pro2.png"
              alt="doctor"
              className="absolute bottom-0 right-7 mobile:hidden"
              width={190}
            />
          </div>
        </div>
      )}

      <TitleCard
        topMargin="mt-2"
        TopSideButtons={
          <TopSideButtons
            applySearch={applySearch}
            applyFilter={applyFilter}
            removeFilter={removeFilter}
          />
        }
      >
        {/* Team Member list in table format loaded constant */}
        <div className="overflow-x-auto w-full">
          <table className="table w-full overflow-hidden h-full">
            {trans?.length > 0 && (
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Ação</th>
                </tr>
              </thead>
            )}
            <tbody>
              {trans?.length > 0 ? (
                trans?.map((l, k) => (
                  <tr key={k}>
                    <td className="font-bold flex ">
                      <span>
                        {l?.name}
                      </span>
                    </td>
                    <td><Link to= {{ pathname : "/detalhe-especialista",  state: { specialist: l} }} > Visualizar </Link></td>
                  </tr>
                ))
              ) : (
                <span className="flex pt-6 text-[#666] font-bold">
                  Nada encontrado.
                </span>
              )}
            </tbody>
          </table>
        </div>
      </TitleCard>
    </>
  );
}

export default ListSpecialists;
